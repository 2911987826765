import React, { useState } from 'react';
// Layouts
import DefaultLayout from './layouts/default';
import axios from 'axios';
import Chatbot from './components/base/chatbot';
import BaseButton from './components/base/Button';
import BaseSection from './components/base/Section';
import BaseAccordion from './components/base/Accordion';
import LandingListItem from './components/landing/ListItem';
import { Analytics } from "@vercel/analytics/react"
import WaitlistForm from './components/base/WaitlistForm';
import FloatingButton from './components/base/Floatingbutton'; 
import Helmet from 'react-helmet';
function App() {
  <Helmet>
  <title>Avano AI</title>
  <meta name="description" content="Experience Avano AI, your ultimate learning buddy. Unlock your full potential with an exciting learning adventure!" />
  <meta property="og:title" content="Avano AI" />
  <meta property="og:description" content="Experience Avano AI, your ultimate learning buddy. Unlock your full potential with an exciting learning adventure!" />
  <meta property="og:image" content="https://avanoai.com/image.jpg" />
</Helmet>
  const [showForm, setShowForm] = useState(false);

  // Function to open the form
  const handleButtonClick = () => {
    setShowForm(true);
  };

  // Function to close the form
  const handleCloseForm = () => {
    setShowForm(false);
  };
  const [isChatbotOpen, setChatbotOpen] = useState<boolean>(false);

  const handleChatbotToggle = () => {
    setChatbotOpen(prev => !prev);
  };
 

  const accordions = [
    {
      title: 'Why should I choose Avano AI?',
      description:
        "Imagine a learning experience that's not just effective, but game-changing. That's Avano. Avano isn’t just a learning tool—it’s a game changer. With our cutting-edge approach and tailor-made coaching, we turn learning into an exhilarating experience. Get ready for smarter, more engaging lessons that fit your style and budget. Choose Avano and see how learning can truly rock!",
    },
    {
      title: 'What makes your teaching approach so unique?',
      description:
        " My approach is all about creating a dynamic and engaging learning experience tailored to your style. I mix interactive exercises, visual aids, real-world examples, and hands-on projects to make sure the material really sticks. The magic happens with personalized attention—I work closely with you to understand your needs and adapt the sessions to fit perfectly.",
    },
    {
      title: 'How do you keep learners motivated and engaged?',
      description:
        "Staying motivated is crucial! That’s why I act as your personal cheerleader and accountability partner. We’ll set achievable goals, celebrate your wins, and build solid study habits to keep you on track. Plus, I love finding fun ways to make learning enjoyable, whether it’s through gamified challenges or friendly competitions.",
    },
    {
      title: 'What kind of results can I expect from working with you',
      description:
        "With me on your side, the possibilities are endless! My students have achieved fantastic results, from landing their dream jobs to launching their own projects. Most importantly, they develop a genuine passion for learning and a growth mindset that drives them forward in all their future endeavors.",
    },
  ];

  return (
    <div className="App">
        {/* Conditionally render WaitlistForm */}
        {showForm && <WaitlistForm onClose={handleCloseForm} />}
        {/*<FloatingButton onClick={handleChatbotToggle} />*/}
      <DefaultLayout> <Analytics/>
        {/* <!-- Hero section --> */}
        <section id="hero" className="w-full pb-24">
          <BaseSection>
          <div className="col-span-12 lg:col-span-6 sm:hidden mb-8">
              <div className="w-full">
                <img
                  src={require('./assets/img/Main4.png')}
                  className="mt-2 sm:-mt-2"
                  alt="Avano ai"
                   loading="lazy"
                />
              </div>
            </div>
            <div className="col-span-12 lg:col-span-6 mt-12 xl:mt-10 space-y-4 sm:space-y-6 px-6 text-center sm:text-left">
            <span
  data-aos="fade-right"
  data-aos-once="true"
  className="bg-clip-text text-transparent font-semibold uppercase"
  style={{
    backgroundImage: 'linear-gradient(169.4deg, #699aa4 -6.01%, #5a8b94 36.87%, #4b7c84 78.04%, #3c6d74 103.77%)',
  }}
>
 <Analytics/>
                Sign Up Today
              </span>
              <h1
                data-aos="fade-right"
                data-aos-once="true"
                className="text-[2.5rem] sm:text-5xl xl:text-6xl font-bold leading-tight capitalize sm:pr-8 xl:pr-10"
                style={{ color: '#186672' }}
             >
                Experience  {' '}
                <span
  className="bg-clip-text text-transparent"
  style={{
    backgroundImage: 'linear-gradient(169.4deg, #14C6EA -6.01%, #2B9FDE 36.87%, #4EB9CA 78.04%, #53ADBA 103.77%)',
  }}
  
>
  Avano AI
</span>

<h2
                data-aos="fade-right"
                data-aos-once="true"
                className="text-[1.5rem] sm:text-4xl xl:text-3xl font-bold leading-tight capitalize sm:pr-8 xl:pr-10"
              >
                Your Learning Buddy  {' '} </h2>
              </h1>
           
              <p
                data-aos="fade-down"
                data-aos-once="true"
                data-aos-delay="300"
                className="paragraph hidden sm:block"
              >
Your AI Learning Sidekick, Making Mastery Fun and Effortless—Like Having a Genius Friend in Your Pocket!
              </p>
              <p
                data-aos="fade-down"
                data-aos-once="true"
                data-aos-delay="300"
                className="paragraph hidden sm:block"
              >
Unlock your potential with personalized support and a touch of brilliance, anytime, anywhere.
              </p>
              <div
                data-aos="fade-up"
                data-aos-once="true"
                data-aos-delay="700"
                className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4 mt-2"
              >
                <BaseButton style="max-w-full px-8 py-4 bg-gradient-to-r from-[#0394B5] to-[#2CB4D6] border border-[#08A0AE] text-white"
                onClick={handleButtonClick}>
                  Join Waitlist 
                </BaseButton>
                <BaseButton style="max-w-full px-6 py-4 bg-inherit text-gradient border border-[#117C95] flex items-center justify-center">
                <span
  className="bg-clip-text text-transparent"
  style={{
    backgroundImage: 'linear-gradient(169.4deg, #699aa4 -6.01%, #5a8b94 36.87%, #4b7c84 78.04%, #3c6d74 103.77%)',
  }}
>
  
  Download App
</span>

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-4 h-4 text-[#117C95]"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                    />
                  </svg>
                </BaseButton>
                <Analytics/>
                </div>
</div>
<div className="hidden sm:block col-span-12 lg:col-span-6">
  <div className="w-full">
    <img
      data-aos="fade-up"
      data-aos-once="true"
      src={require('./assets/img/Main4.png')}
      className="w-full"
      alt="Avano ai"
      loading="lazy"
    />
  </div>
</div>
            <img
              data-aos="fade-up"
              data-aos-delay="300"
              src={require('./assets/img/pattern/ellipse-1.png')}
              className="hidden sm:block absolute bottom-12 xl:bottom-16 left-4 xl:left-0 w-6"
            />
            <img
              data-aos="fade-up"
              data-aos-delay="300"
              src={require('./assets/img/pattern/ellipse-2.png')}
              className="hidden sm:block absolute top-4 sm:top-10 right-64 sm:right-96 xl:right-[32rem] w-6"
            />
            <img
              data-aos="fade-up"
              data-aos-delay="300"
              src={require('./assets/img/pattern/ellipse-3.png')}
              className="hidden sm:block absolute bottom-56 right-24 w-6"
            />
            <img
              data-aos="fade-up"
              data-aos-delay="300"
              src={require('./assets/img/pattern/star.png')}
              className="hidden sm:block absolute top-20 sm:top-28 right-26 lg:right-0 lg:left-[40rem] w-8"
            />
          </BaseSection>
        </section>
        
        {/* <!-- Intro section --> */}
        <section className="w-full my-24">
          <BaseSection>
            <div className="col-span-12 lg:col-span-6 sm:hidden mb-8">
              <div className="w-full">
                <img
                  src={require('./assets/img/Design 6.png')}
                  className="mt-4 sm:-mt-4"
                  alt="Avano ai"
                  loading="lazy"
                />
              </div>
            </div>
            <div
              data-aos="fade-right"
              className="col-span-12 lg:col-span-6 mt-4 xl:mt-20 space-y-6 px-4"
            >
              <h2 className="text-4xl font-semibold sm:pr-8 xl:pr-12 ">
              Meet Avano !! <br />
              </h2>
              <p className="paragraph text-justify">
              Yo there! 
            
              My name's Avano, and I'm stoked to be your personal teaching coach. Get ready for an epic learning journey - 
              I'm bringing the knowledge, the excitement, and the proven strategies to help you crush your goals.
              <br />
This is going to be hands-down the most engaging and empowering educational experience of your life. I've got your back every step of the way, whether you need a cheerleader, a mentor, or just someone to break down complex topics into seriously cool explanations.
<br />
So what are you waiting for? 
Let's dive in and make some magic happen!


              </p>
            
            
              <div className="space-y-6 lg:pr-12">
            
                <BaseButton style="px-8 xl:px-10 py-3 bg-gradient-to-r from-[#0394B5] to-[#2CB4D6] border border-[#08A0AE] text-white text-base font-medium"
                onClick={handleButtonClick}  >
            
                  Talk to Avano <Analytics/>
                </BaseButton >
               
              </div>
            </div>
            <div
              data-aos="fade-left"
              className="col-span-12 lg:col-span-6 hidden sm:block"
              v-bind="$attrs"
            >
              <div className="w-full">
                <img
                  src={require('./assets/img/Design 6.png')}
                  className="mt-4 sm:-mt-4"
                  alt="Avano ai"
                  loading="lazy"
                />
              </div>
            </div>
          </BaseSection>
        </section>
      
        {/* <!-- Zippy Features section --> */}
        <section className="w-full my-36">
          <BaseSection data-aos="fade-down">
            <div className="col-span-12 lg:col-span-7">
              <div className="w-full">
                <img
                  src={require('./assets/img/4d.png')}
                  className="w-[95%]"
                  alt="Avano ai"
                  loading="lazy"
                />
              </div>
            </div>
            <div className="col-span-12 lg:col-span-5 space-y-6 px-4 sm:px-6 mt-20">
              <h2 className="text-4xl font-semibold">
               
              <span
  className="bg-clip-text text-transparent"
  style={{
    backgroundImage: 'linear-gradient(169.4deg, #14C6EA -6.01%, #2B9FDE 36.87%, #4EB9CA 78.04%, #53ADBA 103.77%)',
  }}
>
  Avano's
</span> Features
              </h2> 
              <ul className="space-y-4 sm:space-y-2">
                <LandingListItem title="Personalized Roadmaps">
                  {' '}
                  <p>- Custom curriculum tailored to your needs</p>
                </LandingListItem>
                <LandingListItem title="Assessment Mastery">
                  {' '}
                  <p>- Flashcards, interactive challenges, and more</p>
                </LandingListItem>
                <LandingListItem title="Disciplined Study Guidance ">
                  {' '}
                  <p>- Develop rock-solid habits and time management</p>
                </LandingListItem>
               
              </ul>
              <p className="paragraph text-justify">
              Ready to make learning a blast and unlock your full potential? Jump in now and let us take you on an Avano adventure!
              </p>
              <BaseButton style="px-8 xl:px-10 py-3 bg-gradient-to-r from-[#0394B5] to-[#2CB4D6] border border-[#08A0AE] text-white shadow-md transition-transform transform hover:scale-105 rounded-full 
              "  onClick={handleButtonClick}>
                  Join the WaitList   
                </BaseButton>  
            </div>
          </BaseSection>

        </section>
        {/* <!-- Advanced Learning tools section --> */}
        <section className="bg-trading-tools relative max-w-full sm:mx-4 my-20 py-16 shadow rounded-2xl overflow-hidden">
          <div className="relative max-w-screen-xl px-4 sm:px-2 mx-auto grid grid-cols-12 gap-x-6">
            <div className="col-span-12 lg:col-span-6 sm:hidden">
              <div className="w-full sm:mt-20 xl:mt-0">
                <img
                  src={require(`./assets/img/t.gif`)}
                  className="w-full"
                  alt="Avano ai"
                  loading="lazy"
                />
              </div>
            </div>
            <div
              data-aos="fade-right"
              className="col-span-12 lg:col-span-6 space-y-8 sm:space-y-6 px-4 sm:px-6 mt-8"
            >
              <h2 className="text-4xl font-semibold">
                Premium{' '}
                 
              <span
  className="bg-clip-text text-transparent"
  style={{
    backgroundImage: 'linear-gradient(169.4deg, #14C6EA -6.01%, #2B9FDE 36.87%, #4EB9CA 78.04%, #53ADBA 103.77%)',
  }}
>Learning Tools</span>
              </h2>
        
              <div className="space-y-2">
                <h4 className="text-lg font-medium">
                  Professional Access, Non-stop Availability
                </h4>
                <p className="paragraph text-sm xl:text-base text-justify">
                Unlock premium access to our exclusive 'Knowledge Vault' with top resources, personalized assessments, and one-on-one coaching to help you achieve your goals while having fun.
                </p>
              </div>
              <div className="space-y-2">
                <h4 className="text-lg font-medium">
                Transformative Learning, Anytime, Anywhere
                </h4>
                <p className="paragraph text-sm xl:text-base text-justify">
                Dive into a world of interactive challenges, real-world examples, and hands-on projects that make learning a breeze. With Avano AI, you can master any topic, anywhere, anytime.
                </p>
              </div>
              <div className="space-y-2">
                <h4 className="text-lg font-medium">Elite Support, 24/7</h4>
                <p className="paragraph text-sm xl:text-base text-justify">
                Get the VIP treatment with priority support, personalized coaching, and a community of like-minded learners. With Avano, you're never alone on your learning journey.
                </p>
              </div>
              <div className="flex flex-col sm:flex-row">
              <BaseButton style="px-8 xl:px-10 py-3 bg-gradient-to-r from-[#0394B5] to-[#2CB4D6] border border-[#08A0AE] text-white shadow-md transition-transform transform hover:scale-105 rounded-full 
              "  onClick={handleButtonClick}>
                  Join the WaitList
                  
                </BaseButton>
                <BaseButton style="bg-inherit text-[#0394B5] px-10 py-4 text-center underline rounded-full hover:shadow-none">
                  Learn More
                  <Analytics/>
                </BaseButton>
              </div>
            </div>
            <div
              data-aos="fade-left"
              className="col-span-12 lg:col-span-6 hidden sm:block"
            >
              <div className="w-full sm:mt-20 xl:mt-0">
                <img
                  src={require(`./assets/img/t.gif`)}
                  className="w-full h-"
                  alt="Avano ai"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </section>
       
        <section className="w-full my-24">
  <div className="relative max-w-screen-xl px-8 mx-auto grid grid-cols-12 gap-x-6">
    <div className="col-span-12 text-center">
      <h2 className="text-4xl font-bold mb-12 text-gray-800">Choose             <span
  className="bg-clip-text text-transparent"
  style={{
    backgroundImage: 'linear-gradient(169.4deg, #14C6EA -6.01%, #2B9FDE 36.87%, #4EB9CA 78.04%, #53ADBA 103.77%)',
  }}
> Your Plan </span></h2>
<Analytics/>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
       
        <div
          className="bg-gradient-to-r from-[#0394B5] to-[#2CB4D6] border border-[#08A0AE] border-transparent rounded-xl p-8 shadow-2xl backdrop-blur-md bg-opacity-30 min-h-[400px]"
          data-aos="fade-up"
        >
          <h3 className="text-2xl font-bold text-white mb-6">Basic Plan</h3>
          <ul className="space-y-4 mb-6 text-white text-lg">
            <li>Access to basic resources</li>
            <li>Limited interactive features</li>
            <li>Community support</li>
            <li>.</li>
            <Analytics/>
          </ul>
          <p className="text-3xl font-bold text-white mb-6">Free</p>
          <a href="#free-plan" className="inline-block bg-white text-teal-800 font-bold py-3 px-6 rounded-lg shadow-md hover:bg-gray-100 transition duration-300">Get Started</a>
        </div>
      
        <div
          className="bg-gradient-to-r from-[#0394B5] to-[#2CB4D6] border border-[#08A0AE] border-transparent rounded-xl p-8 shadow-2xl backdrop-blur-md bg-opacity-30 min-h-[400px]"
          data-aos="fade-up"
        >
          <h3 className="text-2xl font-bold text-white mb-6">Standard Plan</h3>
          <ul className="space-y-4 mb-6 text-white text-lg">
            <li>All Free Plan features</li>
            <li>Enhanced interactive tools</li>
            <li>Standard assessments</li>
            <li>Email support</li>
          </ul>
          <Analytics/>
          <p className="text-3xl font-bold text-white mb-6">$5/month</p>
          <a href="#basic-plan" className="inline-block bg-white text-teal-800 font-bold py-3 px-6 rounded-lg shadow-md hover:bg-gray-100 transition duration-300">Get Started</a>
        </div>
    
        <div
          className="bg-gradient-to-r from-[#0394B5] to-[#2CB4D6] border border-[#08A0AE] border-transparent rounded-xl p-8 shadow-2xl backdrop-blur-md bg-opacity-30 min-h-[400px]"
          data-aos="fade-up"
        >
          <h3 className="text-2xl font-bold text-white mb-6">Premium Plan</h3>
          <ul className="space-y-4 mb-6 text-white text-lg">
            <li>All Basic Plan features</li>
            <li>Advanced interactive challenges</li>
            <li>Personalized coaching</li>
            <li>Priority support</li>
           
          </ul>
          <p className="text-3xl font-bold text-white mb-6">$10/month</p>
          <a href="#pro-plan" className="inline-block bg-white text-teal-800 font-bold py-3 px-6 rounded-lg shadow-md hover:bg-gray-100 transition duration-300">Get Started</a>
        </div>
      </div>
    </div>
  </div>
</section>

<Analytics/> 
        {/* <!-- FAQ section --> */}
        <section className="w-full my-24">
          <BaseSection>
            <div
              data-aos="fade-right"
              data-aos-delay="150"
              className="col-span-12 lg:col-span-6"
            >
              <div className="w-full">
                <img
                  src={require('./assets/img/mega.png')}
                  className="w-full"
                  alt="Avano ai"
                  loading="lazy"
                />
              </div>
            </div>
            <div
              data-aos="fade-left"
              data-aos-delay="150"
              className="col-span-12 lg:col-span-6 px-4 sm:px-6 mt-8"
            >
              <span className="text-base text-teal-600 font-semibold uppercase mb-4 sm:mb-2">
                Support
              </span>
              <h2 className="text-3xl sm:text-4xl font-semibold mb-10 sm:mb-6">
                Frequently asked questions
              </h2>
              <Analytics/>
              <ul className="shadow-box">
                {accordions.map((accordion: any) => {
                  return <BaseAccordion accordion={accordion} />;
                })}
              </ul>
            </div>
          
              
          
          </BaseSection>
        </section>

        <div className="w-full my-10 flex justify-center">
          <a
            data-aos="flip-down"
            data-aos-delay="150"
            href="#"
            className="px-6 py-3 flex items-center space-x-2 bg-[#FAFAFA] hover:bg-gray-100 hover:shadow-md border border-[#DDDDDD] rounded-md text-gray-700"
          >
            <span>Back to top</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="h-4"
            >
              <path
                fillRule="evenodd"
                d="M11.47 2.47a.75.75 0 011.06 0l7.5 7.5a.75.75 0 11-1.06 1.06l-6.22-6.22V21a.75.75 0 01-1.5 0V4.81l-6.22 6.22a.75.75 0 11-1.06-1.06l7.5-7.5z"
                clipRule="evenodd"
              />
            </svg>
          </a>
        </div>
      </DefaultLayout>
    </div>
  );
}

export default App;
