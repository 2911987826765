import React, { useState, FormEvent } from 'react';
import { db, collection, addDoc } from '../../firebase';
import BaseButton from './Button';
import '../../assets/css/WaitlistForm.css'; // Create a CSS file for additional styles


interface WaitlistFormProps {
  onClose: () => void;
}

const WaitlistForm: React.FC<WaitlistFormProps> = ({ onClose }) => {
  const [email, setEmail] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [isValidEmail, setIsValidEmail] = useState<boolean>(true);

  const validateEmail = (email: string): boolean => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!validateEmail(email)) {
      setIsValidEmail(false);
      return;
    }

    setIsSubmitting(true);
    setError('');
    setSuccess(false);

    try {
      await addDoc(collection(db, 'waitlist'), {
        email: email,
        timestamp: new Date(),
      });
      setSuccess(true);
      setEmail('');
      setIsValidEmail(true);
    } catch (err) {
      setError('Failed to add to waitlist. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg transform transition-transform scale-95 hover:scale-100 relative">
        <button
          className="absolute top-3 right-3 text-gray-500 hover:text-gray-800"
          onClick={onClose}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
        <h2 className="text-lg font-semibold mb-4">Join Our Waitlist</h2>
        <form onSubmit={handleSubmit} className="flex flex-col items-center space-y-4">
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email"
            className={`px-4 py-2 border rounded-md ${isValidEmail ? 'border-gray-300' : 'border-red-500'}`}
            required
          />
          {!isValidEmail && <p className="text-red-500 text-sm">Please enter a valid email address.</p>}
          <BaseButton
            style="px-8 xl:px-10 py-3 bg-gradient-to-r from-[#0394B5] to-[#2CB4D6] border border-[#08A0AE] text-white shadow-md transition-transform transform hover:scale-105 rounded-full"
            type="submit"
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <div className="flex items-center space-x-2">
                <div className="w-4 h-4 border-t-2 border-white border-solid rounded-full animate-spin"></div>
                <span>Submitting...</span>
              </div>
            ) : (
              'Get on Waitlist'
            )}
          </BaseButton>
          {success && <p className="text-green-500">You have been added to the waitlist!</p>}
          {error && <p className="text-red-500">{error}</p>}
        </form>
      </div>
    </div>
  );
};

export default WaitlistForm;
