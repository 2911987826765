
import React, { ReactNode } from 'react';

interface BaseButtonProps {
  style: string;
  children: ReactNode;
  type?: 'button' | 'submit' | 'reset'; // Adding the 'type' prop
  onClick?: () => void;
  disabled?: boolean;
}

const BaseButton: React.FC<BaseButtonProps> = ({
  style,
  children,
  type = 'button', // Default type is 'button'
  onClick,
  disabled,
}) => {
  return (
    <button
      type={type} // Use the type prop
      className={`text-sm text-center rounded-full hover:shadow-md hover:shadow-[#0c66ee]/50 transition duration-300 ${style}`}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default BaseButton;
