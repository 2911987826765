import { useState } from 'react';
import NavLink from '../NavLink';
import BaseButton from './Button';
import WaitlistForm from './WaitlistForm';

const Navbar = () => {
  const [open, setOpen] = useState(false);
  const [isFormOpen, setIsFormOpen] = useState(false);

  function toggleSidebar() {
    setOpen(!open);
  }

  function toggleForm() {
    setIsFormOpen(!isFormOpen);
  }

  return (
    <nav id="navbar" className="relative z-20 w-full bg-white text-neutral-800 shadow-md">
      <div className="max-w-screen-xl px-8 mx-auto lg:items-center lg:justify-between lg:flex-row py-4">
        <div className="flex flex-col lg:flex-row items-center justify-between lg:space-x-8 relative">
          <div className="flex flex-row items-center w-full lg:w-auto">
            <img
              src={require('./../../assets/img/Logoo.png')}
              className="w-34 h-16"
              alt="Logo"
            />
            <button
              className="lg:hidden ml-auto focus:outline-none transition-transform transform hover:scale-105"
              onClick={toggleSidebar}
            >
              {!open ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              )}
            </button>
          </div>
          <ul
            className={`${
              open ? "flex" : "hidden lg:flex"
            } flex-col lg:flex-row items-center lg:items-center space-y-3 lg:space-y-0 lg:space-x-6 text-teal-600 transition-transform duration-300 absolute lg:static bg-white lg:bg-transparent top-full lg:top-auto left-0 w-full lg:w-auto shadow-lg lg:shadow-none rounded-b-lg lg:rounded-none`}
            style={{ left: open ? "50%" : "0%", transform: open ? "translateX(-50%)" : "none" }}
          >
            <NavLink name="About" url="#" />
            <NavLink name="Demo" url="#" />
            <NavLink name="Waitlist" url="#" />
            <div className="flex items-center">
              <NavLink name="Products" url="#" />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="h-4 -ml-3 mt-1 text-blue-600 transition-transform transform hover:rotate-180"
              >
                <path
                  fillRule="evenodd"
                  d="M12.53 16.28a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 011.06-1.06L12 14.69l6.97-6.97a.75.75 0 111.06 1.06l-7.5 7.5z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
          </ul>
          <div className={`${
            open ? "flex" : "hidden lg:flex"
          } space-x-3 lg:space-x-6 mt-4 lg:mt-0`}>
            <BaseButton
              style="px-8 xl:px-10 py-3 bg-gradient-to-r from-[#0394B5] to-[#2CB4D6] border border-[#08A0AE] text-white shadow-md transition-transform transform hover:scale-105 rounded-full"
              onClick={toggleForm} // Open the form on button click
            >
              Get on Waitlist
            </BaseButton>
          </div>
        </div>
        {/* Curved bottom edge */}
        <div className="absolute bottom-0 left-0 w-full h-12 bg-white rounded-t-full shadow-lg -z-10"></div>
      </div>
      {/* Render WaitlistForm conditionally */}
      {isFormOpen && <WaitlistForm onClose={toggleForm} />}
    </nav>
  );
};

export default Navbar;
