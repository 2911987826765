import React from "react";
import NavLink from "../NavLink";
import { ReactComponent as Logo } from "../../assets/img/logo/nefa.svg";

const Footer = () => {
  return (
    <footer className="max-w-screen-xl px-8 mx-auto">
      <div className="w-full border-y border-[#DDDDDD]">
        <div className="flex flex-col lg:flex-row">
          
          {/* Image section */}
          <div className="flex justify-start items-center lg:w-1/4 p-6 lg:p-12 border-b lg:border-b-0 border-[#DDDDDD]">
            <a href="#">
              <img
                src={require('./../../assets/img/logo.png')}
                className="w-18 h-18"
                alt="Avano AI"
              />
            </a>
          </div>
          
          {/* Footer Links */}
          <div className="flex-1 lg:w-1/2 p-6 lg:p-12 border-b lg:border-b-0 border-[#DDDDDD]">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
              <div>
                <ul className="space-y-4">
                  <NavLink name="Products" url="#" />
                  <NavLink name="About Us" url="#" />
                  <NavLink name="Careers" url="#" />
                  <NavLink name="Blog" url="#" />
                </ul>
              </div>
              <div>
                <ul className="space-y-4">
                  <NavLink name="Help Center" url="#" />
                  <NavLink name="Contact Us" url="#" />
                  <NavLink name="Terms & Conditions" url="#" />
                  <NavLink name="Privacy Policy" url="#" />
                </ul>
              </div>
            </div>
          </div>
          
          {/* Newsletter */}
          <div className="lg:w-1/4 p-6 lg:p-12">
            <h5 className="text-sm font-medium text-[#666666]">Newsletter</h5>
            <p className="text-sm text-[#666666]">
              Elevate your Learning journey with Avano AI! <br className="sm:hidden" />
              - subscribe to the newsletter now!
            </p>
            <div className="flex items-center space-x-2 mt-4">
              <input
                type="text"
                className="w-full px-2 py-4 rounded-lg text-sm border border-[#AAAAAA] placeholder-[#888]"
                placeholder="Enter your email"
              />
              <button className="px-4 py-4 bg-gradient-to-r from-[#0394B5] to-[#2CB4D6] border border-[#08A0AE] rounded-md text-white hover:shadow-md transition duration-300">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="h-4"
                >
                  <path
                    fillRule="evenodd"
                    d="M12.97 3.97a.75.75 0 011.06 0l7.5 7.5a.75.75 0 010 1.06l-7.5 7.5a.75.75 0 11-1.06-1.06l6.22-6.22H3a.75.75 0 010-1.5h16.19l-6.22-6.22a.75.75 0 010-1.06z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>
          </div>
          
        </div>
      </div>
      <div className="py-8 sm:py-4 text-center text-sm text-[#666666] hover:text-gray-900">
        &copy; Avano AI 2024
      </div>
    </footer>
  );
};

export default Footer;
